import request from './base/request';

const handleRequest = async (method, type = '', data = null) => {
  try {
    const baseURL = '/setting/auto-approve';
    const endpoint = type ? `${baseURL}/${type}` : baseURL;
    const response = await request[method](endpoint, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAutoApprovalSetting = (type = '') => handleRequest('get', type);

export const updateAutoApprovalSetting = (type = '', data) => handleRequest('put', type, data);


// export { getAutoApprovalSetting, updateAutoApprovalSetting }