import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_10_CHARACTOR,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  ORGANIZATIONAL_DIVISION
 } from '@/constants/registerData';
 import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
 import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number } from './wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { prepareMasterOrCustomizeDB, getDbCustomizeOrDbMasterById } from '@/concerns/utils/master-or-customize-db'
import {addThoundSandComma} from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { handleLostFocus } from '@/utils/csvImportHandler'
import { dbIdeaObj } from '@/api/ideaDbSource';
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';

import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
const energyTypes = [
  {
    key: 'ガソリン',
    value: 'ガソリン'
  },
  {
    key: '軽油',
    value: '軽油'
  },
  {
    key: 'その他',
    value: 'その他'
  }
]

const targetModel = [
  {
    key: '軽貨物車',
    value: '軽貨物車'
  },
  {
    key: '小型貨物車',
    value: '小型貨物車'
  },
  {
    key: '普通貨物車',
    value: '普通貨物車'
  },
  {
    key: '船舶',
    value: '船舶'
  },
  {
    key: '鉄道',
    value: '鉄道'
  },
  {
    key: '航空',
    value: '航空'
  }
]

export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    energy_type: null,
    target_model: null,
    loading_data_maximum: null,
    loading_data_rate: null,
    unit: null,
    distance: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    loading_data_source: null,
    distance_source : null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    fuel: null
  }
}

const calcEmissions = (valueSource, distance, loadingDataMaximum, loadingDataRate) => {
  if($_helper_isNumberType(valueSource)&& $_helper_isNumberType(distance) && $_helper_isNumberType(loadingDataMaximum) && $_helper_isNumberType(loadingDataRate)){
    const valSource = math.bignumber(formatValue(valueSource));
    const valDistance = math.bignumber(formatValue(distance));
    const valLoadingDataMaximum = math.bignumber(formatValue(loadingDataMaximum));
    // const valLoadingDataRate = math.bignumber(formatValue(loadingDataRate));
    return  formatBigNumber(math.evaluate(`${valSource.toString()} * ${valDistance.toString()} *  ${valLoadingDataMaximum.toString()}`), 50)
  
  }
  return 0;

  //return Math.abs(valueSource * distance * loadingDataMaximum * loadingDataRate / 100)
}

const validateNumberField = (input, propName = '') => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }
    const number10Char = formatValue(input).includes('-') ? 11 : 10
    if (formatValue(input).length > number10Char && propName === 'loading_data_rate') {
      return MESSAGE_MAX_10_CHARACTOR;
    }
    //remove check negative number
    // if ($_helper_isNegativeNumber(input)) {
    //   return MESSAGE_POSITIVE_NUMBER;
    // }
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}

const notNullInView = [
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const notNullList = [
  'organizational_division',
  'company_name',
  'fuel',
  'target_model',
  'loading_data_maximum',
  'loading_data_rate',
  'distance',
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const max10Charactor = [
  'loading_data_rate',
]

const max25Charactor = [
  'loading_data_maximum',
  'distance',
  // 'wsu_value',
  'wsu_unit'
]

const max128Charactor = [
  // 'company_name',
  // 'business_name',
  'fuel',
  'target_model',
  'memo_1',
  'memo_2',
  'loading_data_source',
  'distance_source'
  // 'wsu_source', TODO
]

const numberField = [
  'loading_data_maximum',
  'loading_data_rate',
  'distance',
]

let isReverted = false;
let searchID = null;
const NA_VALUE = 'N/A';

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, cellData, currentItem, dataStore } = dataBinding
  if (binding == 'distance') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem.loading_data_rate)
      s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
    })
  }

  if (binding === 'wsu_source') {
    let dbItem =  dataStore[cellData]
    if (searchID && !dbItem) {
      dbItem =  dataStore[searchID]
    }
    const { SERVICE_LINK, IDEA } = DB_TYPE;
      currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id
    s.deferUpdate(() => {
      if (dbItem) {
        if(dbItem?.type === SERVICE_LINK) {
          s.rows[row].dataItem.detailExternalProductCode = currentItem.idDbExternal
          s.setCellData(row, getColumnIndexByBinding(s, 'distance'), dbItem.numberValue, false, true)
        } else {
          s.rows[row].dataItem.detailExternalProductCode = null
        }
        s.setCellData(row, getColumnIndexByBinding(s, 'wsu_source'), dbItem.id, false, true)
        s.setCellData(row, getColumnIndexByBinding(s, 'wsu_value'), dbItem?.value_source, false, true)
        s.setCellData(row, getColumnIndexByBinding(s, 'wsu_unit'), dbItem?.unit_source, false, true)

        const emissions = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem.loading_data_rate)
        s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
      } else {
        s.setCellData(row, getColumnIndexByBinding(s, 'wsu_value'), null, false, true)
        s.setCellData(row, getColumnIndexByBinding(s, 'wsu_unit'), null, false, true)
        const emissions = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem.loading_data_rate)
        s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
      }
    })
  }

  if (binding === 'loading_data_maximum') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem.loading_data_rate)
      s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
    })
  }

  if (binding === 'loading_data_rate') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem.loading_data_rate)
      s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
    })
  }
}
let filterIndex = {};
const partern2 = {
  initializeGrid(flexgrid, itemCount, branchData = {}, dbStore) {
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }

      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      const dataStore = getDbCustomizeOrDbMasterById(dbStore.dbCustomizes)

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }

          const cellData = s.getCellData(rowIndex, colIndex, false)
          const currentItem = source[rowIndex] || {}
          const binding = s.columns[colIndex].binding
          const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore }
          autoBindingDbSource(dataBinding)
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}
      const binding = column.binding
      const dataStore = getDbCustomizeOrDbMasterById(dbStore.dbCustomizes);
      const item = s.rows[e.row].dataItem;

      const dataBinding = { s, binding, row, cellData, currentItem, dataStore }
      autoBindingDbSource(dataBinding)

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;

      if (
        column.binding === 'wsu_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'emissions'
      ) {
        e.cancel = true;
      }
      if (!item.business_name) {
        item.business_name = null;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[Number(item?.clientRowId) - 1 || row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
    
  },

 header(registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, dbStore, grid, isRemoveStatusColumn) {
    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_fuel'),
        binding: 'fuel',
        minWidth: 95,
        maxWidth: 400,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(energyTypes, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: energyTypes,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      {
        header: i18n.t('56_pattern.table_target_model'), // Loại xe thuộc đối tượng targetModel
        binding: 'target_model',
        minWidth: 95,
        maxWidth: 480,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(targetModel, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: targetModel,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      {
        header: i18n.t('56_pattern.table_memo_1'), // memo1
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t('56_pattern.table_memo_2'), // memo2
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t('56_pattern.table_loaded_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_loading_data_maximum'),
            binding: 'loading_data_maximum',
            minWidth: 141,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_loading_data_rate'), // Loại xe thuộc đối tượng
            binding: 'loading_data_rate',
            minWidth: 148,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_loading_data_source'),
            binding: 'loading_data_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_transportation_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_transport_distance'), // Energy quantity data (numerical value)
            binding: 'distance',
            minWidth: 142,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_distance_source'),
            binding: 'distance_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_exhaust_original'),
        align: 'center',
        width: 660,
        columns: [
          {
            header: i18n.t('56_pattern.table_wsu_value'), // Đơn vị nguồn thải
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_wsu_unit'), // Đơn vị nguồn thải (unit)
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_wsu_source'), // Đơn vị nguồn thải (source)
            binding: 'wsu_source',
            minWidth: 350,
            maxWidth: 950,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(prepareMasterOrCustomizeDB(dbStore?.dbCustomizes), 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
            placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: prepareMasterOrCustomizeDB(dbStore?.dbCustomizes),
              lostFocus: (sender, ctx) => {
                searchID = handleLostFocus({ ctx, sender, dbStore, registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, grid, isRemoveStatusColumn }, partern2.header)
              },
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            }),
          },
        ],
      },
      {
        header      : i18n.t('56_pattern.table_external_basic_unit'),
        binding     : "view_detail",
        maxWidth    : getWidthOfSearchButton(i18n.locale),  
        allowSorting: false,
        isRequired  : false,
        wordWrap    : true,
        cssClassAll : "search-detail",
        cellTemplate: CellMaker.makeButton({
          text : 
            `<div class="search-container">
              ${i18n.t('register_data.button_search')}
              <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
            </div>`,
          click: (e, ctx) => externalSourcePopup(ctx, 'wsu_source')
        }),
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataTable, bindingLayout, isSaveTemplate = false, branchData, isGetBranchDetail = true, dbStore) {
    let databaseTypeObject = {};
    const wsuSource = dataTable.wsu_source;
    if(dataTable.idDbExternal && dbIdeaObj[dataTable.idDbExternal]) {
      databaseTypeObject = dbIdeaObj[dataTable.idDbExternal]
    } else {
      databaseTypeObject = dbStore.getDbCustomizeOrDbMasterById[wsuSource] || {};
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }
    dataTable.wsu_source = databaseTypeObject?.id || null;
    dataTable.wsu_value = databaseTypeObject.value_source || databaseTypeObject.wsu_value || null;
    dataTable.wsu_unit = databaseTypeObject.unit_source || null;
    if(!isGetBranchDetail) return
    const emissions = calcEmissions(dataTable.wsu_value, dataTable.distance, dataTable.loading_data_maximum, dataTable.loading_data_rate);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};
    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      fuel: dataTable.fuel?.trim() || null,
      target_model: dataTable.target_model || null,
      loading_data_maximum: isSaveTemplate ? '' : formatValue(dataTable.loading_data_maximum) === 0 ? '0' : formatValue(dataTable.loading_data_maximum) || null,
      loading_data_rate: isSaveTemplate ? '' : formatValue(dataTable.loading_data_rate) === 0 ? '0' : formatValue(dataTable.loading_data_rate) || null,
      distance: isSaveTemplate ? '' :formatValue(dataTable.distance) === 0 ? '0' : formatValue(dataTable.distance) || null,
      emissions: formatEmission25Number(emissions),
      loading_data_source: dataTable.loading_data_source || null,
      distance_source: dataTable.distance_source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      db_customize_id: databaseTypeObject.origin_id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      type: databaseTypeObject.type,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_relation: dataTable.db_relation ||  null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }
    if(dataTableItemCustom.type === DB_TYPE.SERVICE_LINK || dataTableItemCustom.type === DB_TYPE.IDEA || dataTable.idDbExternal) {
      const dbRelationKey = getDbRelationKeysByType(dataTableItemCustom.type);
      dataTableItemCustom.db_relation = {
        [dbRelationKey]: databaseTypeObject.origin_id
      }
      // dataTableItemCustom.type = 2
      dataTableItemCustom.db_customize_id = null
      dataTableItemCustom.db_master_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'energy_type',
    'target_model',
    'unit',
    'wsu_unit',
    'wsu_source',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

    if (notNullList.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]), propName);
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if (max10Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return `入力された組織区分/法人名/${itemsInViewError.map(item => item)}の組み合わせが存在しません。`;
      }
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'fuel',
      'target_model',
      'loading_data_maximum',
      'loading_data_rate',
      'loading_data_source',
      'distance',
      'distance_source',
      'wsu_source',
      'attach_file',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'fuel',
      'target_model',
      'loading_data_maximum',
      'loading_data_rate',
      'loading_data_source',
      'distance',
      'distance_source',
      ...bindingLayout
    ];
  },

  autoFields: [
    'wsu_value',
    'wsu_unit',
    'wsu_source'
  ],

  patternUrl: '/pattern-s3-c9/2',
  valueSource: 'wsu_source',

  calcEmissions(rowData) {
    return calcEmissions(rowData.wsu_value, rowData.distance, rowData.loading_data_maximum, rowData.loading_data_rate)
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]

        // check length
        const maxlength128 = 128;
        const maxlength25 = 25;
        const maxlength10 = 10;
        
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max25Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength25) {
          const emission25Number = formatValue(rowData?.[column.binding])?.includes('-') ? 26 : 25;
          const newData = addThoundSandComma(formatValue(rowData?.[column.binding])?.substring(0, emission25Number)) || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength25
        }

        if(max10Charactor.includes(column.binding) && rowData?.[column.binding]) {
          const emission10Number = formatValue(rowData?.[column.binding])?.includes('-') ? 11 : 10;
          const newData = addThoundSandComma(formatValue(rowData?.[column.binding])?.substring(0, emission10Number)) || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength10
        }
        //end check length

        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      if(column.binding === 'organizational_division') {
        contentMaxWidth = ORGANIZATIONAL_DIVISION[dataMaxLengthInview.organizational_division - 1]
      }

      const widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true) 
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const column = theGrid.columnHeaders.columns[col];
    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);
    if(column.binding === 'wsu_source') {
      widthCellEditing = callBackGetWidth(store.getters['registerData/getDbCustomizeOrDbMasterById'][cellData]?.source) + paddingCell
    }
    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = source[indexMaxLengthOfColumn]
        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview?.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'distance',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['distance', 'wsu_value', 'loading_data_maximum', 'loading_data_rate']
};

export default partern2;
