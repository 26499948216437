<template>
  <div class="offset-transaction-page padding-left-layout padding-top-layout">
    <page-title :title="textTitle" :textOnlyJp="$t('offset_transaction.title_only_jp')"/>
    <div class="item">
      <div class="page-header">
        <h2 class="header-text">{{ $t("offset_transaction.label_what_offset_trading") }}</h2>
        <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
      </div>
      <div class="graphic-wrapper">
        <div class="graphic-header">
          {{ $t("offset_transaction.description_offset_trading") }}
        </div>
        <div class="graphic-images">
          <img src="@/assets/images/offset-transaction/graphic1.svg" alt="" />
          <img
            v-if="$vuetify.breakpoint.width < 1024"
            class="second-image"
            src="@/assets/images/offset-transaction/graphic2-mobile.svg"
            alt=""
          />
          <img v-else class="second-image" src="@/assets/images/offset-transaction/graphic2.svg" alt="" />
          <img src="@/assets/images/offset-transaction/graphic3.svg" alt="" />
        </div>
        <div class="graphic-footer">
          <div class="graphic-footer-text">{{ $t("offset_transaction.label_grasping_ghg_emissions") }}</div>
          <div class="graphic-footer-text">{{ $t("offset_transaction.label_efforts_reduce_emissions") }}</div>
          <div class="graphic-footer-text">{{ $t("offset_transaction.label_understand_emissions") }}</div>
          <div class="graphic-footer-text">{{ $t("offset_transaction.label_implement_forest_protection") }}</div>
          <div class="graphic-footer-text">
            {{ $t("offset_transaction.label_difficult_to_reduce") }}
          </div>
        </div>
      </div>
    </div>
    <div class="item" style="padding-top: 0 !important">
      <div class="page-header" id="bottom-title" style="padding-bottom: 0">
        <h2 class="header-text">{{ $t("offset_transaction.label_consultation_support") }}</h2>
        <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
      </div>
      <div class="steps-wrapper">
        <div class="step step1-wrapper">
          <div class="step-header">
            <div>
              <div class="step-image">
                <h3>STEP</h3>
                <h3>1</h3>
              </div>
              <h3 class="step-header-text">{{ $t("offset_transaction.label_send_email_to_carbonix") }}</h3>
            </div>
            <div class="divider" />
          </div>
          <div class="step-content">
            <div class="step-content-header">
              {{ $t("offset_transaction.description_send_email_to_carbonix") }}
            </div>
            <div class="step-content-wrapper">
              <div class="header">{{ $t("offset_transaction.label_customer_infomation") }}</div>
              <div class="email-information">
                <div class="email-step">
                  <h4 class="step-title">{{ $t("offset_transaction.label_type_credit") }}</h4>
                  <ul class="step-content">
                    <li>{{ $t("offset_transaction.label_credit_j") }}</li>
                    <li>{{ $t("offset_transaction.label_green_power_certificate") }}</li>
                    <li>{{ $t("offset_transaction.label_non_fossil_certificate") }}</li>
                    <li>{{ $t("offset_transaction.label_undecided_unknow") }}</li>
                  </ul>
                </div>
                <div class="email-step">
                  <h4 class="step-title">{{ $t("offset_transaction.label_use_credit") }}</h4>
                  <div class="step-content">
                    <div>{{ $t("offset_transaction.label_warming_law_energy") }}</div>
                  </div>
                </div>
                <div class="email-step">
                  <h4 class="step-title">{{ $t("offset_transaction.label_amount_procurement") }}</h4>
                  <div class="step-content">
                    <div>{{ $t("offset_transaction.label_unit") }}</div>
                  </div>
                </div>
                <div class="email-step">
                  <h4 class="step-title">{{ $t("offset_transaction.label_procurement_timing") }}</h4>
                </div>
                <div class="email-step">
                  <h4 class="step-title">{{ $t("offset_transaction.label_other_question") }}</h4>
                </div>
              </div>
              <a class="submit-button" href="mailto:offset@carbonix.ai">
                <img src="@/assets/images/offset-transaction/letter.svg" alt="" />
                <div>{{ $t("offset_transaction.button_send_consultation_email") }}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="step step2-wrapper">
          <div class="step-header">
            <div>
              <div class="step-image">
                <h3>STEP</h3>
                <h3>2</h3>
              </div>
              <h3 class="step-header-text">{{ $t("offset_transaction.label_contact_form") }}</h3>
            </div>
            <div class="divider" />
          </div>
          <div class="step-content">
            <div>{{ $t("offset_transaction.description_representative") }}</div>
            <div>{{ $t("offset_transaction.description_depending_type") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import { ROUTES } from '@/router/constants';
import { mapActions } from 'vuex';
export default {
  components: { PageTitle },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t("offset_transaction.hyperlink_home"),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION,
        },
        {
          text: this.$t("offset_transaction.label_offset_trading"),
          disabled: true,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.OTHERS + '/' + ROUTES.OFFSET_TRANSACTION,
        },
      ],
    };
  },
  computed: {
    textTitle() {
      return this.$t("offset_transaction.title_offset_trading")
    },
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
  },
};
</script>
<style lang="scss" scoped>
.offset-transaction-page {
  .page-title {
    margin-top: 40px;
  }
  .page-header {
    padding: 32px 0 48px 0;
    display: flex;
    flex-flow: column;
    img {
      width: 44px;
      height: 8px;
      margin-top: 16px;
    }
    .header-text {
      color: $monoBlack;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.1em;
    }
  }
  .graphic-wrapper {
    .graphic-header {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
    .graphic-images {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 48px 0;
      img {
        width: 286px;
        height: 141px;
        margin-bottom: 32.5px;
        &:first-child {
          width: 220px;
          height: 288px;
        }
        &:last-child {
          width: 221px;
          height: 288px;
          margin-bottom: unset;
        }
      }
    }
    .graphic-footer {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $monoBlack;
    }
  }
  .item {
    &:last-child {
      .page-header {
        //padding-top: 80px;
      }
      margin-bottom: 128px;
    }
  }
  .steps-wrapper {
    .step {
      .step-header {
        margin-bottom: 24px;
        .step-image {
          width: 73px;
          height: 70px;
          border: 1px solid $goldMid;
          border-radius: 8px;
          padding: 16px 20px 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: $goldMid;
          h3 {
            font-family: 'Century Gothic Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.15em;
            &:last-child {
              font-size: 22px;
              line-height: 26px;
              letter-spacing: 0.1em;
              margin-top: 4px;
            }
          }
        }
        .step-header-text {
          margin-top: 20px;
          color: $monoBlack;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.1em;
        }
      }
      .step-content {
        .step-content-header {
          color: $monoBlack;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
          margin-bottom: 48px;
        }
        .step-content-wrapper {
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: $monoBlack;
            margin-bottom: 7px;
          }
          .email-information {
            display: flex;
            flex-flow: column;
            margin-bottom: 39px;
            .email-step {
              border: 1px solid $dividerBlack;
              margin: 10px 0;
              padding: 16px 20px;
              color: $goldMid;
              font-style: normal;
              line-height: 24px;
              .step-title {
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.03em;
              }
              .step-content {
                font-weight: 400;
                font-size: 13px;
                letter-spacing: 0.05em;
                margin-top: 16px;
              }
              &:first-child {
                margin-top: unset;
              }
              &:last-child {
                margin-bottom: unset;
              }
            }
          }
          a.submit-button {
            background: $goldMid;
            padding: 14px 0 18px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: $monoWhite !important;
            text-decoration: unset !important;
            box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
            transition: 0.1s ease-out;
            &:hover {
              background: #a4a184;
              box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
            }
            div {
              color: $monoWhite;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.03em;
              margin-left: 8px;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        ul {
          padding-left: 20px;
          li {
            &::marker {
              font-size: 10px;
            }
          }
        }
      }
      &.step1-wrapper {
        margin-bottom: 96px;
        margin-top: 48px;
      }
      &.step2-wrapper {
        .step-header {
          margin-bottom: 24px;
        }
        .step-content {
          color: $monoBlack;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
        }
      }
      .divider {
        border: 1px solid $dividerBlack;
        margin-top: 22px;
      }
    }
  }
  #bottom-title {
    padding-top: 80px;
  }
}
@include desktop {
  .offset-transaction-page {
    .item {
      width: 600px;
      margin: 0 auto;
      padding: unset !important;
      padding-top: 32px !important;
      .page-header {
        margin-bottom: 48px;
        padding: unset;
      }
      .graphic-images {
        padding: unset;
      }
      &:last-child {
        padding: 0 265px;
        margin-top: 80px;
        .page-header {
          padding-bottom: 48px;
        }
      }
      .graphic-wrapper {
        .graphic-images {
          flex-flow: row;
          justify-content: center;
          align-items: unset;
          padding-top: 24px;
          .second-image {
            margin: 0 52px;
            width: 200px;
            height: 192px;
          }
          img {
            margin-bottom: 24px;
          }
        }
      }
      .steps-wrapper {
        .step {
          .step-header {
            display: flex;
            flex-flow: column;
            & > div:first-child {
              display: flex;
              div {
                margin-left: 24px;
              }
            }
            .divider {
              width: 100%;
            }
          }
          .step-image {
            margin-left: unset !important;
            margin-right: 24px;
          }
        }
        .email-step {
          margin: 8px 0 !important;
          display: flex;
          .step-title,
          .step-content {
            width: 272px;
          }
          .step-content {
            margin-top: unset !important;
            margin-left: 16px;
          }
        }
      }
    }
    .page-title {
      margin-top: 0;
    }
    #bottom-title {
      padding-top: 0px;
    }
  }
}
</style>
